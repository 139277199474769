import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import Section from '../components/Section';
import LegalTextblock from '../components/LegalTextblock';
import LinkWrapper from '../components/LinkWrapper';
import { colors } from '../helpers/variables';

/** Angepasster Link */
const StyledLink = styled(LinkWrapper)`
    color: ${colors.primary};
`;

const ImprintPage = () => (
    <Layout title="Impressum" description="Schütz Dienstleistungen - Impressum">
        <Section bg="gray" container="l">
            <LegalTextblock>
                <div>
                    <h1>Impressum</h1>
                    <h2>Herausgeber & inhaltlich verantwortlich</h2>
                    <strong>Schütz Dienstleistungen</strong>
                    <br />
                    Reinigung & Wartung
                    <br />
                    Peter Schütz
                    <br />
                    Reinigung & Wartung
                    <br />
                    72631 Aichtal-Neuenhaus
                </div>
                <div>
                    <h2>Kontakt</h2>
                    <strong>Schütz Dienstleistungen</strong>
                    <br />
                    Reinigung & Wartung
                    <br />
                    Peter Schütz
                    <br />
                    Reinigung & Wartung
                    <br />
                    72631 Aichtal-Neuenhaus
                    <br />
                    {/* eslint-disable-next-line */}
                    Tel: <StyledLink to="tel: 07127 / 50311"> 07127 / 50311</StyledLink>
                    <br />
                    Fax: 07127 / 969155
                    <br />
                    <StyledLink to="mailto:info@schuetzdienstleistungen.de">
                        info@schuetzdienstleistungen.de
                    </StyledLink>
                </div>
                <div>
                    <h2>Sitz der Gesellschaft</h2>
                    Aichtal-Neuenhaus
                    <br />
                    Eingetragen beim Amtsgericht Stuttgart
                </div>
            </LegalTextblock>
        </Section>
    </Layout>
);

export default ImprintPage;
